import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Appbar from '../Appbar/Appbar';
import IconButton from '@mui/material/IconButton';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { Typography } from '@mui/material';
export default class TrendingWordFooter extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getTrendingWordsAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.componentMethods.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    handleChipClick () {
        return true
    }
    getTrendingWordsAction() {
        const that = this;
        const date = new Date();
        const dateYear = date.getFullYear();
        let dateMonth = date.getMonth();
        dateMonth++;
        if(date.getMonth() < 10) {
            dateMonth = '0' + dateMonth
        }
        let dateDay = date.getDate();
        if(dateDay < 10) {
            dateDay = '0' + dateDay;
        }
        let searchDate = dateYear + '-' + dateMonth + '-' + dateDay;
        that.props.componentMethods.setManagerState({loadingTrendingWords: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_WHITELISTED_TODAY + '?limit=50'  ,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.componentMethods.setManagerState({trendingWords: data.items, loadingTrendingWords:false})
                } else {
                    that.props.componentMethods.setManagerState({loadingTrendingWords:false})
                }
            })
        })
    }

    deleteTrendingWhitelistAction(trendingWhitelistID) {
        const that = this;
        that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_WHITELIST + '/' + trendingWhitelistID + '?accountToken=' + that.getLocalStorage('user').tokensHash  ,{},'DELETE')
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.rootTrendingWords}>
                    {
                        !this.props.componentMethods.getManagerState().loadingTrendingWords && this.props.componentMethods.getManagerState().trendingWords.length === 0 ?
                        <Typography style={classes.cilcularProgress} variant="body2">{this.props.componentMethods.getManagerState().language.LANG_EMPTY_TRENDING_WHITELISTED_LIST}</Typography>
                        :
                        null
                    }
                    {
                        this.props.componentMethods.getManagerState().loadingTrendingWords ?
                                null
                            :
                            <div style={{padding: 0, display: 'inline-flex', height: 50, width: '100%', margin: '0 auto', overflowX: 'scroll'}}>
                                {
                                    this.props.componentMethods.getManagerState().trendingWords.map((item, index)=> {
                                        return <a  key={index} href={"/search/" + item.trendingWordsKeyword} style={{display:'inline-block', marginLeft: 4, marginRight: 4}}>
                                                    <Chip label={item.trendingWordsKeyword} color="primary" onClick={() => {this.handleChipClick()}}>
                                                    </Chip>
                                                </a> 
                                        })
                                }
                            </div>
                    }                                        
                </div>
    }
}
