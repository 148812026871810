import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Appbar from '../Appbar/Appbar';
import IconButton from '@mui/material/IconButton';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from '@mui/material/Button';

import { Typography } from '@mui/material';
export default class TrendingWhitelistedPage extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getTrendingWordsAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    getTrendingWordsAction() {
        const that = this;
        const date = new Date();
        const dateYear = date.getFullYear();
        let dateMonth = date.getMonth();
        dateMonth++;
        if(date.getMonth() < 10) {
            dateMonth = '0' + dateMonth
        }
        let dateDay = date.getDate();
        if(dateDay < 10) {
            dateDay = '0' + dateDay;
        }
        let searchDate = dateYear + '-' + dateMonth + '-' + dateDay;
        that.props.setManagerState({loadingTrendingWords: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_WHITELISTED_TODAY + '?limit=50'  ,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({trendingWords: data.items, loadingTrendingWords:false})
                } else {
                    that.props.setManagerState({loadingTrendingWords:false})
                }
            })
        })
    }

    deleteTrendingWhitelistAction(trendingWhitelistID) {
        const that = this;
        that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_WHITELIST + '/' + trendingWhitelistID + '?accountToken=' + that.getLocalStorage('user').tokensHash  ,{},'DELETE')
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    {
                        !this.props.getManagerState().loadingTrendingWords && this.props.getManagerState().trendingWords.length === 0 ?
                        <Typography style={classes.cilcularProgress} variant="body2">{this.props.getManagerState().language.LANG_EMPTY_TRENDING_WHITELISTED_LIST}</Typography>
                        :
                        null
                    }
                    {
                        this.props.getManagerState().loadingTrendingWords ?
                                <CircularProgress style={classes.cilcularProgress} text={this.props.getManagerState().language.LANG_GENERATING_FEED} />
                            :
                            <Grid container spacing={2}>
                            {
                                this.props.getManagerState().trendingWords.map((item, index)=> {
                                    return <Grid item xs={12} md={4} lg={3} key={index}>
                                                <a href={"/search/" + item.trendingWordsKeyword}>{item.trendingWordsKeyword}</a> 
                                                <Button onClick={(e) => { this.deleteTrendingWhitelistAction(item.trendingWhitelistID)} } variant="outlined" color="secondary" endIcon={<ThumbDownOffAltIcon />}>{this.props.getManagerState().language.LANG_REMOVE_FROM_WHITELIST}</Button>
                                            </Grid>
                                    })
                            }
                            </Grid>
                    }                                        
                </div>
    }
}
