import * as React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Style from '../Styles/LoginStyle'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Settings from '../../../Settings/Settings'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoginIcon from '@mui/icons-material/Login';
import RecoverPasswordScreen from './RecoverPasswordScreen'
export default function LoginForm(props) {
  const [logInUsersEmail, setLogInUsersEmail] = React.useState()
  const [logInUsersPassword, setLogInUsersPassword] = React.useState()
  const classes = Style();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const logInAction = () => {
      componentMethods.setManagerState({loadingLogin: true})
      let requestLogInObject = {
          usersEmail: logInUsersEmail,
          usersPassword:logInUsersPassword,
      }
      componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_LOGIN,requestLogInObject,'POST',null, (data) => {
        componentMethods.saveLocalStorage(data.items[0], 'user')
        componentMethods.setManagerState({loadingLogin: false, searchFeedItems: []}, () => {
          if (props.refreshPage)
          {
            window.location.reload();            
          }
          if (componentConstants.urlParams && componentConstants.urlParams.query)
          {
            getLinksAction(componentConstants.urlParams.query)
          }  
        })
        getSubscribersAction()
        if (props.redirectToHome)
        {
          // componentMethods.changeRoute('/')
          document.location.href = '/';
        }
      }, {}, false, (data) => {componentMethods.setLogInErrors(data)}, () => {
          componentMethods.setManagerState({loadingLogin: false})
      })
  }
  const createAccountAction = () => {
    componentMethods.changeRoute('/create-account');
  }

  const showLogin = () => {
    componentMethods.setManagerState({showLogin: true})
  }
  const getLinksAction = (q) => {
    componentMethods.getLinksAction(q)
  }
  const getSubscribersAction = () => {
      const that = this;
      componentMethods.setManagerState({loadingSubscribers: true},() => {
          if(componentMethods.getLocalStorage('user')) {
              componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '?accountToken=' + componentMethods.getLocalStorage('user').tokensHash,{},'GET','subscribers', (data) => {
                  componentMethods.setManagerState({subscribers: data.items, loadingSubscribers: false})
              })
          }    
      })
  }
  if (!componentMethods.getManagerState().showLogin)
  {
    return <Paper style={classes.logimFormPaper}>
      <Button startIcon={<LoginIcon />} fullWidth onClick={showLogin} color="secondary" style={classes.button} variant="contained">{componentConstants.language.LANG_LOGIN_TO_USE_ZOOTZLE}</Button>
      </Paper>
  }
  if(componentMethods.getManagerState().recoverPasswordScreen) {
    return <Paper style={classes.logimFormPaper}>
              <RecoverPasswordScreen componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            </Paper>
  }

  if (componentMethods.getLocalStorage('user'))
  {
    return null
  }
  return (
      <Paper style={classes.logimFormPaper}>
    		<TextField
              required
              id="loginEmail"
              fullWidth
              label={componentConstants.language.LANG_EMAIL}
              style={classes.loginInput}
              onChange={(e)=> {setLogInUsersEmail(e.target.value)}}
            />     
        <TextField
              required
              id="loginPassword"
              type="password"
              style={classes.loginInput}
              fullWidth
              label={componentConstants.language.LANG_PASSWORD}
              onChange={(e)=> {setLogInUsersPassword(e.target.value)}}
            />  
            {
              componentMethods.getManagerState().loadingLogin ?
              <CircularProgress />
              :
              <React.Fragment>
                <Button startIcon={<LoginIcon />} fullWidth onClick={logInAction} color="secondary" style={classes.button} variant="contained">{componentConstants.language.LANG_AUTHENTICATE}</Button>
                <Button startIcon={<PersonAddIcon />} fullWidth onClick={createAccountAction} color="secondary" style={classes.button} variant="outlined">{componentConstants.language.LANG_CREATE_ACCOUNT}</Button>
                <Button disabled={componentMethods.getManagerState().loadingLogin} onClick={componentMethods.showRecoverPasswordScreen} variant="contained" color="primary" fullWidth={true} style={classes.closeButton}>
                  {componentConstants.language.LANG_RECOVER_PASSWORD}
              </Button>
              </React.Fragment>

            }  
      </Paper>
  );
}

