import color from '@mui/material/colors/blue';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
export default function Style(theme) {
  const style = {
    root: {
      marginTop: 80,
      marginBottom: 80
    },
    rootWords: {
      marginTop: 80,
      marginBottom: 80,
      padding: 8
    },
    rootTrendingWords:
    {
      marginTop: 80
    },
    paperWords: {
      padding: 8
    },
    rootSearchFeedWithoutMarginTop: {
      marginBottom: 80
    },
    rootNewsFeed: {
      marginTop: 20
    },
    newsFeedItem: {
      margin: "8px auto",
      height: '100%'
    },
    cilcularProgress: {
      margin: "0 auto"
    },
    link: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1rem",
      color: blue[800]
    },
    sublink: {
      color: green[400]
    },
    loadMoreButton: {
      marginTop: 8,
      marginBottom: 8,
      textAlign: "center"
    },
    subscriberHeader: {
      position:"sticky", 
      top: 56, 
      backgroundColor:color[50],
      zIndex: 100,
      marginTop: 26
    },
    olderButtonContainer: {
      margin: '8px auto',
      textAlign: 'center',

    },
    loadingOlder: {
      margin: '8px auto',
      textAlign: 'center'
    },
    menuDrawerLinks: {
      textDecoration: "none",
      color: color[500],
      width: '100%',
      display: "block"
    },
    addToWatchlistButton: {
      margin: "8px auto",
    },
    addToWatchlistButtonContainer: {
      textAlign: "center"
    },
    subscriptionsDrawer: {
      position: 'fixed',
      right: 10,
      bottom: 10
    },
    commentPager: {
      padding: 8,
      margin: '8px 0 8px 0'
    },
    sendCommentButton: {
      margin: "8px 0 8px 0"
    },
    seoLink: {
      color: '#fff',
      fontSize: 1
    }
  }
    return style
}