import React from 'react';
import Typography from '@mui/material/Typography';

export default function GDPRPageComponent(props) {
    
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    
  return (<React.Fragment>
            <Typography variant="h3" component="h3">
                Confidentiality Policy
            </Typography>
            <Typography variant="h4">
                1. Introduction
            </Typography>
            <Typography variant="body1">
                This Confidentiality Policy outlines our commitment to maintaining the privacy and security of personal data in accordance with the General Data Protection Regulation (GDPR). It applies to all employees, contractors, and third parties who handle personal data on behalf of Zootzle News
            </Typography>
            <Typography variant="h4">
                2. Definitions
            </Typography>
            <Typography variant="body1">
                Personal Data: Any information relating to an identified or identifiable natural person. Data Processing: Any operation or set of operations performed on personal data.
            </Typography>
            <Typography variant="h4">
                3. Scope
            </Typography>
            <Typography variant="body1">
                This policy applies to all personal data collected, processed, stored, or transmitted by Zootzle News, regardless of the format or medium.
            </Typography>
            <Typography variant="h4">
                4. Principles
            </Typography>
            <Typography variant="body1">
                Lawfulness, Fairness, and Transparency: Personal data shall be processed lawfully, fairly, and transparently.
                Purpose Limitation: Personal data shall be collected for specified, explicit, and legitimate purposes and not further processed in a manner incompatible with those purposes.
                Data Minimization: Personal data shall be adequate, relevant, and limited to what is necessary in relation to the purposes for which they are processed.
                Accuracy: Personal data shall be accurate and, where necessary, kept up to date.
                Storage Limitation: Personal data shall be kept in a form that permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed.
                Integrity and Confidentiality: Personal data shall be processed in a manner that ensures appropriate security, including protection against unauthorized or unlawful processing and against accidental loss, destruction, or damage.
            </Typography>
            <Typography variant="h4">
                5. Responsibilities
            </Typography>
            <Typography variant="body1">
                Data Controller: Zootzle News is responsible for determining the purposes and means of processing personal data.
                Data Processor: Zootzle News is responsible for processing personal data on behalf of the data controller.
            </Typography>
            <Typography variant="h4">
                6. Confidentiality Measures
            </Typography>
            <Typography variant="body1">
                Access Controls: Access to personal data shall be restricted to authorized personnel only.
                Encryption: Personal data shall be encrypted when transmitted electronically or stored on portable devices.
                Training: All personnel shall receive training on GDPR requirements and confidentiality measures.
                Confidentiality Agreements: All employees, contractors, and third parties shall sign confidentiality agreements.
            </Typography>
            <Typography variant="h4">
                7. Data Subject Rights
            </Typography>
            <Typography variant="body1">
                Data subjects have the right to access, rectify, erase, restrict processing, object to processing, and data portability.
                Requests from data subjects shall be promptly addressed in accordance with GDPR requirements.
            </Typography>
            <Typography variant="h4">
                8. Data Breach Response
            </Typography>
            <Typography variant="body1">
            In the event of a data breach, Zootzle News shall promptly assess the risk to data subjects and notify the relevant supervisory authority and affected data subjects as required by GDPR.
            </Typography>
            <Typography variant="h4">
                9. Review and Revision
            </Typography>
            <Typography variant="body1">
                This Confidentiality Policy shall be reviewed and updated as necessary to ensure ongoing compliance with GDPR requirements.
            </Typography>
            <Typography variant="h4">
                10. Contact Information
            </Typography>
            <Typography variant="body1">
                For inquiries regarding this Confidentiality Policy or GDPR compliance, please contact Zootzle News at zootzle.romania@gmail.com.
            </Typography>
      </React.Fragment>);
}