import React from 'react';
import Style from './Styles/SearchOrdersStyle';
import Settings from '../../../Settings/Settings'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';


export default function SearchOrdersComponent(props) {
    const classes = Style();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    
    let yourUrlParamsSearchQuery = ''
    if(componentConstants.urlParams && componentConstants.urlParams.query) {
      yourUrlParamsSearchQuery = componentConstants.urlParams.query
    }
    
    const [vouchersQuery, setVouchersQuery] = React.useState(yourUrlParamsSearchQuery)

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          searchUsers(event)
        }
    }

    const searchUsers = (e) => {
      e.preventDefault()
          if(vouchersQuery) {
            componentMethods.changeRoute('/admin/users/search/' + vouchersQuery)
              // componentMethods.searchUsers(vouchersQuery)
          } else {
            // componentMethods.searchUsers(vouchersQuery)
            componentMethods.changeRoute('/admin/users')
          }
      }

    return   <React.Fragment>
      <Grid item xs={1} lg={4} md={1}></Grid>
    <Grid item xs={10} lg={4} md={10}>
      <Paper component="form" style={classes.root}>
    <InputBase
      style={classes.input}
      placeholder={componentConstants.language.LANG_SEARCH_USERS + "..."}
      inputProps={{ 'aria-label': componentConstants.language.LANG_SEARCH_USERS + "..." }}
      value={vouchersQuery} 
      onChange={(event)=>{ setVouchersQuery(event.target.value)}}
      onKeyPress={handleKeyPress}
    />
    <IconButton type="button" onClick={searchUsers} style={classes.iconButton} aria-label="search">
      <SearchIcon />
    </IconButton>
    </Paper> </Grid><Grid item xs={1} lg={4} md={1}></Grid>
    </React.Fragment>
}
