import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../Appbar/Appbar';
import GDPRPageComponent from './MaterialComponents/GDPRPageComponent';
export default class StaticPagesComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        let pageContent = null
        switch (this.props.page) {
            case 'gdpr-policy':
                pageContent = <GDPRPageComponent />
                break;
        }
        this.props.setManagerState({pageContent: pageContent})
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '70px auto',
              maxWidth: '750px',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            img: {
                maxWidth: '150px',
                maxHeight: '150px'
            },
          };
        this.loadComponentsProps()
        
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent postWebsiteVisitors={true}  websiteVisitors={true} {...this.buildComponentProps('AppbarComponent')} />
                    {this.props.getManagerState().pageContent}
                </div>
    }
}
