import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Settings from '../../Settings/Settings';
import Style from './Styles/YourAccountPaperStyle'
import Appbar from '../Appbar/Appbar';
import YourAccountPaper from './Subcomponents/YourAccountPaper'
import Paper from '@mui/material/Paper';
export default class YourAccountComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: this.props.urlParams
        }
        if(!this.getLocalStorage('user')) {
            this.props.changeRoute('/')
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getUserAddresses()
        if(!this.getLocalStorage('user')) {
            this.props.changeRoute('/')
        }

        if(! this.props.getManagerState().createAccountYears.items.length) {
            this.ajax(Settings.API_URL + Settings.API_ENDPOINT_REGISTER_YEAR,{},'GET',null, (data) => {this.setCreateAccountYears(data)})
        }
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            setYourAccountData: (data) => {
                that.setYourAccountData(data)
            },
            setUpdateAccountErrors: (data) => {
                that.setUpdateAccountErrors(data)
            },
            getUserAddresses: () => {
                that.getUserAddresses()
            },
            logoutUser: () => {
                that.logoutUser()
            }
        })
    }

    setAddressCounties (data) {
        this.props.setManagerState({counties: data})
    }

    setCreateAccountYears (data) {
        this.props.setManagerState({createAccountYears: data})
    }
    setYourAccountData(data) {
        let newUser = data.items[0]
        let oldUser = this.getLocalStorage('user')
        oldUser.usersEmail = newUser.usersEmail
        oldUser.usersFirstName = newUser.usersFirstName
        oldUser.usersLastName = newUser.usersLastName
        oldUser.usersUsersSexID = newUser.usersUsersSexID
        oldUser.usersDateOfBirth = newUser.usersBirthYear + '-' + newUser.usersBirthMonth + '-' + newUser.usersBirthDay
        this.saveLocalStorage(oldUser,'user')
        this.props.setManagerState({loadingYourAccount: false})
        this.addErrorMain(this.props.getManagerState().language.LANG_UPDATED_ACCOUNT)

    }

    getUserAddresses() {
        const that = this;
        that.props.setManagerState({addresses: {
            items: []
        }}, () => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADDRESS + '?accountToken=' + that.getLocalStorage('user').tokensHash,{},'GET','addresses', (data) => {
                    that.setUserAddresses(data)
                })
            }    
        })
    }

    setUserAddresses (data) {
        this.props.setManagerState({addresses: data})
    }
    
    logoutUser () {
        const that = this
        this.saveLocalStorage(null, 'user')

        this.props.setManagerState({user: null, addresses: {
            items: []
        }}, () => {
            that.props.changeRoute('/')
        })
    }


    setUpdateAccountErrors(data) {
        this.props.setManagerState({loadingYourAccount: false})
        let invalidFields = {}
        let errorMessage = [this.props.getManagerState().language[data.message] ]
       
        if(data.fields.usersEmail) {
            invalidFields.formInvalidYourAccountUsersEmail = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersEmail"] + ': ' + this.props.getManagerState().language[data.fields.usersEmail.message])
        }

        if(data.fields.usersPassword) {
            invalidFields.formInvalidYourAccountNewPassword = true;
            invalidFields.formInvalidYourAccountNewPasswordConfirm = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersPasswordCheck"] + ': ' + this.props.getManagerState().language[data.fields.usersPassword.message])
        }

        if(data.fields.usersPasswordCheck) {
            invalidFields.formInvalidYourAccountOldPassword = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersPasswordCheck"] + ': ' + this.props.getManagerState().language[data.fields.usersPassword.message])
        }

        if(data.fields.usersFirstName) {
            invalidFields.formInvalidYourAccountUsersFirstName = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersFirstName"] + ': ' + this.props.getManagerState().language[data.fields.usersFirstName.message])
        }
        if(data.fields.usersLastName) {
            invalidFields.formInvalidYourAccountUsersLastName = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersLastName"] + ': ' + this.props.getManagerState().language[data.fields.usersLastName.message])
        }
        if(data.fields.usersDateOfBirth) {
            invalidFields.formInvalidYourAccountUsersDateOfBirth = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersDateOfBirth"] + ': ' + this.props.getManagerState().language[data.fields.usersDateOfBirth.message])
        }

        if(data.fields.usersUsersSexID) {
            invalidFields.formInvalidYourAccountUsersUsersSexID = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersUsersSexID"] + ': ' + this.props.getManagerState().language[data.fields.usersUsersSexID.message])
        }

        this.addErrorMain(errorMessage.join(', '), invalidFields)
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <Paper style={classes.rootPaper}>
                    <div style={classes.root}>
                        <Appbar postWebsiteVisitors={true} {...this.buildComponentProps('Appbar')} />
                        <div style={classes.pageContainer}>
                            <YourAccountPaper {...this.buildComponentProps('Appbar')} />
                        </div>
                    </div>
                </Paper>
    }
}
