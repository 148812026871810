import React from 'react';
import CoreComponent from "../../../../../Core/CoreComponent";
import Theme from '../../../../../Themes/ThemeArtLexie'
import Style from '../../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../../Settings/Settings';
import NewsItem from '../../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import color from '@mui/material/colors/blue';

export default class SubscribersFeed extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addError(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.componentMethods.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.props.componentMethods.getSubscribersFeedAction()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
        }).setComponentsConstants('LoginForm', {
            ...this.basicComponentConstants
        }).setComponentsMethods('LoginForm', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }

    getSubscribersOlderFeedAction(subscribersId) {
        const that = this;
         let subscribersIDRequest = '&limit=24';
        that.props.componentMethods.setManagerState({loadingOlderNewsFeed: true, loadingOlderSubscribersID: subscribersId},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS_FEED_ALL_OLRDER + that.getOldestSubscriberLinksID(subscribersId) + '/'  + subscribersId + '?accountToken=' + that.getLocalStorage('user').tokensHash + subscribersIDRequest,{},'GET','subscribersFeed', (data) => {
                        that.appendSubscribersLinks(subscribersId, data.items)
                })
            }    
        })
    }

    getOldestSubscriberLinksID (subscribersID) {
        let feedsItems = this.props.componentMethods.getManagerState().subscribersFeedItems;
        if (feedsItems.length) {
            for (let i in feedsItems) {
                if (parseInt(feedsItems[i].subscriber.subscribersID) === parseInt(subscribersID)) {
                    if (feedsItems[i].links.content.items[feedsItems[i].links.content.items.length -1])
                        return feedsItems[i].links.content.items[feedsItems[i].links.content.items.length -1].linksID
                    else
                        return 0;
                }
            }    
        }
    }

    appendSubscribersLinks(subscribersID, items) {
        if(items && items.length) {
            let feedsItems = this.props.componentMethods.getManagerState().subscribersFeedItems;
            for (let i in feedsItems) {
                if (parseInt(feedsItems[i].subscriber.subscribersID) === parseInt(subscribersID)) {
                    feedsItems[i].links.content.items = feedsItems[i].links.content.items.concat(items)
                    break;
                }
            }
            this.props.componentMethods.setManagerState({subscribersFeedItems:feedsItems, loadingOlderNewsFeed: false })
        }
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.rootNewsFeed}>                               
                    <Grid container spacing={2}>
                    { this.props.componentMethods.getManagerState().loadingNewsFeed ?
                        <div style={classes.loadingOlder}>
                            <CircularProgress style={classes.cilcularProgress} />
                        </div>
                        :
                        null
                    }
                    {
                        this.props.componentMethods.getManagerState().subscribersFeedItems.map((item, index)=> {
                            return <React.Fragment key={index}>
                                <Grid item xs={12} md={12} lg={12} style={classes.subscriberHeader}>
                                    <Typography variant="h6" style={{textAlign: "center", color: color[500]}}>{item.subscriber.subscribersKeyword}</Typography>
                                </Grid>
                                {item.links.content.items.map((itemLink, indexLink) => {
                                    return <Grid item xs={12} md={4} lg={3} key={indexLink}>
                                                <NewsItem showBookmark={true} link={itemLink} key={index} {...this.buildComponentProps("Appbar")} />
                                            </Grid>
                                    })
                                }
                                { !this.props.componentMethods.getManagerState().loadingOlderNewsFeed ?
                                    <Grid item xs={12} md={12} lg={12} style={classes.loadMoreButton}>
                                        <Button color="secondary" variant="outlined" onClick={() => {this.getSubscribersOlderFeedAction(item.subscriber.subscribersID)}}>{this.props.componentMethods.getManagerState().language.LOAD_MORE_FOR} {item.subscriber.subscribersKeyword}</Button>
                                    </Grid>
                                    :
                                    <React.Fragment>
                                    { this.props.componentMethods.getManagerState().loadingOlderSubscribersID  == item.subscriber.subscribersID ?
                                    <div style={classes.loadingOlder}>
                                        <CircularProgress style={classes.cilcularProgress} />
                                    </div>
                                    :
                                    null
                                    }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        })
                    }
                    </Grid>
                </div>
    }
}
