import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import color from '@mui/material/colors/blue';

export default class SearchFeed extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
        }
        this.state = {page:1}
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addError(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.componentMethods.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.props.componentMethods.getSubscribersFeedAction()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
        })
    }
    
    isWordSubscribed(word) {
        let isWordSubscribed = false;
        this.props.componentMethods.getManagerState().subscribers.map((item, index) => {
            if(item.subscribersKeyword.toLowerCase() === word.toLowerCase()) {
                isWordSubscribed = true
            }
        })
        return isWordSubscribed;
    }
    getOlderAction() {
        const that = this;
        let query = ""
        if (that.props.componentConstants.urlParams && that.props.componentConstants.urlParams.query)
        {
            query = that.props.componentConstants.urlParams.query
        }
        if (query !== "")
            {
                that.props.componentMethods.setManagerState({loadingOlderSearchFeed: true},() => {
                    let strict = 0
                    if (this.getQueryParams('strict') === '1')
                    {
                        strict = 1   
                    }

                    that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_LINKS_V3 + "?q=" + query + '&page='+(this.state.page+1) + '&strict=' + strict,{},'GET',null, (data) => {
                        let links = this.props.componentMethods.getManagerState().searchFeedItems;
                        links = links.concat(data.items);
                        that.props.componentMethods.setManagerState({loadingOlderSearchFeed: false, searchFeedItems: links})
                        if (data.items.length > 0)
                        {
                            that.setState({page: that.state.page+1})
                        }
                    })
                })                      
            } else {
                let linksID = this.props.componentMethods.getManagerState().searchFeedItems[this.props.componentMethods.getManagerState().searchFeedItems.length - 1].linksID;
                that.props.componentMethods.setManagerState({loadingOlderSearchFeed: true},() => {
                    that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_LINKS_OLDER  + linksID + "?q=" + query,{},'GET',null, (data) => {
                        let links = this.props.componentMethods.getManagerState().searchFeedItems;
                        links = links.concat(data.items);
                        that.props.componentMethods.setManagerState({loadingOlderSearchFeed: false, searchFeedItems: links})
                    })
                })        
            }
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={this.props.withoutMargin?classes.rootSearchFeedWithoutMarginTop : classes.root}>                                 
                    <Grid container spacing={2}>
                    {
                        (this.props.componentMethods.getManagerState().loadingNewsFeed || this.props.componentMethods.getManagerState().loadingSearchFeed) && !this.props.hideLoading?
                                <div style={classes.loadingOlder}>
                                    <CircularProgress style={classes.cilcularProgress} text={this.props.componentMethods.getManagerState().language.LANG_GENERATING_FEED} />
                                </div>
                            :
                            null
                    }       
                    {this.props.componentConstants.urlParams && this.props.componentConstants.urlParams.query ?
                        <React.Fragment>
                            <Grid item xs={12} md={12} lg={12} style={classes.subscriberHeader}>
                                <Typography variant="h6" style={{textAlign: "center", color: color[500]}}>{this.props.componentConstants.language.LANG_SEARCH_RESULTS_FOR} &quot;{this.props.componentConstants.urlParams.query}&quot;</Typography>
                            </Grid>
                        </React.Fragment>
                    :
                    null
                    }

                    {this.props.componentConstants.urlParams && this.props.componentConstants.urlParams.query ?
                                <Grid item xs={12} md={12} lg={12}>
                                    <div style={classes.addToWatchlistButtonContainer}>
                                    {!this.isWordSubscribed(this.props.componentConstants.urlParams.query) && !this.props.componentMethods.getManagerState().loadingSubscribers && this.getLocalStorage('user')?
                                            <Button color="secondary" variant="outlined" style={classes.addToWatchlistButton} onClick={() => {this.props.componentMethods.postSubscriberKeyword(this.props.componentConstants.urlParams.query)}}>{this.props.componentMethods.getManagerState().language.LANG_ADD_TO_WATCHLIST} {this.props.componentConstants.urlParams.query}</Button>
                                        :
                                        null
                                    }
                                    {this.props.componentConstants.urlParams.query.split(" ").length > 1?
                                        <div style={{textAlign:'center', marginTop: 16}}>
                                        {this.getQueryParams('strict') === '1' ?
                                        <a style={{color:color[500]}} href={this.setQueryParams('strict', '0')}>{this.props.componentMethods.getManagerState().language.LANG_USING_STRICT_SEARCH_TEXT} &quot;{this.props.componentConstants.urlParams.query}&quot;</a>
                                        :
                                        <a style={{color:color[500]}} href={this.setQueryParams('strict', '1')}>{this.props.componentMethods.getManagerState().language.LANG_USING_NOT_STRICT_SEARCH_TEXT} &quot;{this.props.componentConstants.urlParams.query}&quot;</a>
                                        }
                                        </div>
                                    :
                                    null}
                                    </div>
                                </Grid>
                                :
                                null
                    }
                    {
                        this.props.componentMethods.getManagerState().searchFeedItems.map((item, index)=> {
                            return <Grid item xs={12} md={4} lg={3} key={index}>
                                        <NewsItem showBookmark={this.getLocalStorage('user') ? true : false} link={item} key={index} {...this.buildComponentProps("Appbar")} />
                                    </Grid>
                            })
                    }
                    {this.props.componentMethods.getManagerState().searchFeedItems.length ?
                        <React.Fragment>
                        {this.props.componentMethods.getManagerState().loadingOlderSearchFeed ?
                            <div style={classes.loadingOlder}>
                                <CircularProgress style={classes.cilcularProgress} />
                            </div>
                            :
                            <React.Fragment>
                            {this.props.componentConstants.urlParams && this.props.componentConstants.urlParams.query ?
                                <Grid item xs={12} md={12} lg={12} style={classes.loadMoreButton}>
                                    <Button color="secondary" variant="outlined" onClick={() => {this.getOlderAction()}}>{this.props.componentMethods.getManagerState().language.LOAD_MORE_FOR} {this.props.componentConstants.urlParams.query}</Button>
                                </Grid>                            
                            :
                                <Grid item xs={12} md={12} lg={12} style={classes.loadMoreButton}>
                                    <Button color="secondary" variant="outlined" onClick={() => {this.getOlderAction()}}>{this.props.componentMethods.getManagerState().language.LOAD_MORE}</Button>
                                </Grid>     
                            }
                            </React.Fragment>
                            
                        }
                        </React.Fragment>
                        :
                        <React.Fragment>
                        {this.props.componentConstants.urlParams && this.props.componentConstants.urlParams.query && !this.props.componentMethods.getManagerState().searchFeedItems.length && !this.props.componentMethods.getManagerState().loadingSearchFeed ?
                            <Typography component="div" variant="h6" style={{textAlign: "center", width: "100%"}}>{this.props.componentConstants.language.LANG_NO_RESULTS}</Typography>
                        :
                        null
                        }
                        </React.Fragment>
                    }
                    </Grid>
                </div>
    }
}
