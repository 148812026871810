import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Style from '../Styles/LoginStyle';
import settings from '../../../Settings/Settings';

export default function RecoverPasswordScreenComponent(props) {
    const classes = Style();

    const componentConstants = {
    ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const [recoverUsersEmail, setRecoverUsersEmail] = React.useState("")
    const recoverUsersPasswordAction = () => {
        let requestObject = {
            usersEmail: recoverUsersEmail
        }
        componentMethods.ajax(settings.API_URL + settings.API_ENDPOINT_USER_RECOVER_PASSWORD,requestObject,'POST',null, (data) => {
           componentMethods.addError(componentConstants.language.LANG_SUCCESSFUL_RECOVER_EMAIL_SENT)
        })
    }
  return (<form className={classes.root} noValidate autoComplete="off">
        <Typography variant="h5" component="h5" className={classes.title}>
            {componentConstants.language.LANG_RECOVER_PASSWORD}
        </Typography>
        <TextField value={recoverUsersEmail} className={classes.formFullWidth} onChange={(event) => {setRecoverUsersEmail(event.target.value)}} id="CREATE_ACCOUNT_EMAIL" label={componentConstants.language.LANG_EMAIL} fullWidth={true} />
        {componentMethods.getManagerState().loadingRecoverPassword ?
            <CircularProgress />
        :
            <React.Fragment>
                <Button onClick={recoverUsersPasswordAction} variant="contained" disableElevation color="secondary" fullWidth={true}  style={classes.button}>
                    {componentConstants.language.LANG_RECOVER}
                </Button> 
                <Button disabled={componentMethods.getManagerState().loadingLogin} onClick={componentMethods.hideRecoverPasswordScreen} variant="outlined" color="secondary" fullWidth={true}  style={classes.button}>
                    {componentConstants.language.LANG_BACK}
                </Button>
            </React.Fragment>
        }
    </form>
  );
}