import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Style from '../Home/Styles/HomeStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Grid from '@mui/material/Grid';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import color from '@mui/material/colors/blue';

export default class Zopinions extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        this.getZopinions()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.getSubscribersFeedAction()
            },
            getLinksAction: (q) => {
                that.getLinksAction(q)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
        })
    }
    
    getZopinions() {
        const that = this;
        that.props.setManagerState({loadingZopinions: true, zopinions: []},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_ZOPINIONS + '?limit=50',{},'GET',null, (data) => {
                that.props.setManagerState({zopinions: data.items, loadingZopinions: false})
            })
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar postWebsiteVisitors={true} {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        <Typography variant="h6" style={{textAlign: "center", color: color[500]}}>{this.props.getManagerState().language.LANG_ZOPINIONS}</Typography>
                        { this.props.getManagerState().loadingZopinions ?
                            <div style={classes.loadingOlder}>
                                <CircularProgress style={classes.cilcularProgress} />
                            </div>
                            :
                            null
                        }
                            {this.props.getManagerState().zopinions.map((itemLink, indexLink) => {
                                return <Paper variant="outlined" style={classes.commentPager} key={indexLink}>
                                                {
                                                    itemLink.comments.map((item, index) => {
                                                        return <Paper variant="outlined" style={classes.commentPager} key={index}>
                                                                <Typography variant="subtitle2" gutterBottom>{item.usersFirstName}:</Typography>
                                                                <Typography variant="subtitle1" gutterBottom>{item.commentsText}</Typography>
                                                            </Paper>
                                                    })
                                                }
                                            <NewsItem showBookmark={true} link={itemLink.link} {...this.buildComponentProps("Appbar")} />
                                        </Paper>
                                })
                            }
                    </div>
                </div>
    }
}
