import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Appbar from '../Appbar/Appbar';

export default class BokkmarksPage extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getBookmarks()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    getBookmarks() {
        const that = this;
        that.props.setManagerState({loadingBookmarks: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_POST_BOOKMARKS + '?limit=50&accountToken=' + that.getLocalStorage('user').tokensHash  ,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({bookmarks: data.items, loadingBookmarks:false})
                }
            })
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar postWebsiteVisitors={true} {...this.buildComponentProps('Appbar')} />
                    {
                        this.props.getManagerState().loadingBookmarks ?
                                <CircularProgress style={classes.cilcularProgress} text={this.props.getManagerState().language.LANG_GENERATING_FEED} />
                            :
                            <Grid container spacing={2}>
                            {
                                this.props.getManagerState().bookmarks.map((item, index)=> {
                                    return <Grid item xs={12} md={4} lg={3} key={index}>
                                                <NewsItem showBookmark={false} link={item} key={index} {...this.buildComponentProps("Appbar")} />
                                            </Grid>
                                    })
                            }
                            </Grid>
                    }                                        
                </div>
    }
}
