import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Settings from '../../Settings/Settings';
import Style from './Styles/CreateAccountPaperStyle'
import Appbar from '../Appbar/Appbar';
import CreateAccountPaper from './Subcomponents/CreateAccountPaper'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoginForm from '../Login/Subcomponents/LoginForm';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';

export default class CreateAccountComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: this.props.urlParams
        }
    }

    componentDidMount() {
        super.componentDidMount()
        if(! this.props.getManagerState().createAccountYears.items.length) {
            this.ajax(Settings.API_URL + Settings.API_ENDPOINT_REGISTER_YEAR,{},'GET',null, (data) => {this.setCreateAccountYears(data)})
        }
    }

    setCreateAccountYears (data) {
        this.props.setManagerState({createAccountYears: data})
    }

    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            showRecoverPasswordScreen: () => {
                that.props.setManagerState({recoverPasswordScreen: true})
            },
            hideRecoverPasswordScreen: () => {
                that.props.setManagerState({recoverPasswordScreen: false})
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
                addError: (errorMessage, stateObject) => {
                    that.addErrorMain(errorMessage, stateObject)
                },
                setSuccessfulUsersRegister : (data) => {
                    that.setSuccessfulUsersRegister(data)
                },
                setCreateAccountErrors: (data) => {
                    that.setCreateAccountErrors(data)
                },
        })
    }

    setSuccessfulUsersRegister (data) {
        if (data.httpStatusCode === 200) {
            this.props.setManagerState({successfulUsersRegister: true, loadingCreateAccount: false})
        }
    }

    setCreateAccountErrors(data) {
        let invalidFields = {}
        let errorMessage = [this.props.getManagerState().language["LANG_VALIDATION_INVALID_REQUEST"] ]
        if(data.fields.usersEmail) {
            invalidFields.formInvalidCreateAccountUsersEmail = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersEmail"] + ': ' + this.props.getManagerState().language[data.fields.usersEmail.message])
        }
        if(data.fields.usersPassword) {
            invalidFields.formInvalidCreateAccountPassword = true;
            invalidFields.formInvalidCreateAccountPasswordConfirm = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersPassword"] + ': ' + this.props.getManagerState().language[data.fields.usersPassword.message])
        }

        if(data.fields.usersFirstName) {
            invalidFields.formInvalidCreateAccountUsersFirstName = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersFirstName"] + ': ' + this.props.getManagerState().language[data.fields.usersFirstName.message])
        }
        if(data.fields.usersLastName) {
            invalidFields.formInvalidCreateAccountUsersLastName = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersLastName"] + ': ' + this.props.getManagerState().language[data.fields.usersLastName.message])
        }
        if(data.fields.usersDateOfBirth) {
            invalidFields.formInvalidCreateAccountUsersDateOfBirth = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersDateOfBirth"] + ': ' + this.props.getManagerState().language[data.fields.usersDateOfBirth.message])
        }

        if(data.fields.usersUsersSexID) {
            invalidFields.formInvalidCreateAccountUsersUsersSexID = true;
            errorMessage.push(this.props.getManagerState().language["LANG_usersUsersSexID"] + ': ' + this.props.getManagerState().language[data.fields.usersUsersSexID.message])
        }

        this.addErrorMain(errorMessage.join(', '), invalidFields)
        this.props.setManagerState({loadingCreateAccount: false})
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        if(this.props.getManagerState().successfulUsersRegister) {
            return (
                <Paper style={classes.rootPaper}>
                <div style={classes.root}>
                    <Appbar postWebsiteVisitors={true} {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        <Typography variant="h5" component="h5" style={classes.title}>
                        {this.props.getManagerState().language.LANG_SUCCESSFUL_REGISTER}
                        </Typography>
                        <LoginForm {...this.buildComponentProps('Appbar')} redirectToHome={true} />
                        <Button onClick={() => {
                            this.props.setManagerState({successfulUsersRegister: false})
                            }} variant="contained" disableElevation color="secondary" fullWidth={true} style={classes.closeButton}>
                            {this.props.getManagerState().language.LANG_BACK}
                        </Button>
                    </div>
                </div>
                </Paper>
                )
        }
        return <Paper style={classes.rootPaper}>
                    <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        <CreateAccountPaper {...this.buildComponentProps('Appbar')} />
                    </div>
                </div>
            </Paper>
    }
}
