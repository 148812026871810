import React from 'react';
import { useParams
} from "react-router-dom";
import { Routes ,Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import FiltersList from '../Components/FiltersList/FiltersList'
import NewsItem from '../Components/NewsItemPage/NewsItemPage'
import Bookmarks from '../Components/Bookmarks/BookmarksPage'
import Trending from '../Components/Trending/TrendingPage'
import TrendingWhitelisted from '../Components/TrendingWhitelist/TrendingWhitelisted'
import TrendingTodayPage from '../Components/TrendingTodayPage/TrendingTodayPage'
import Subscribers from '../Components/SubscribersPage/SubscribersPage';
import CreateAccount from '../Components/CreateAccount/CreateAccount';
import YourAccount from '../Components/YourAccount/YourAccount'
import Zopinions from '../Components/Zopinions/Zopinions';
import RecoverPasswordComponent from '../Components/RecoverPasswordComponent/RecoverPasswordComponent';
import WordsEditor from '../Components/WordsExitor/WordEditor'
import WordsEditorQa from '../Components/WordsEditorQa/WordEditor'
import StaticPagesComponent from '../Components/StaticPagesComponent/StaticPageComponent';
import TrendingWhitelistWords from '../Components/TrendingWhitelistWords/TrendingWhitelistWords';
import TrendingBlacklistWords from '../Components/TrendingBlacklistWords/TrendingBlacklistWords';
import AdminUsersComponent from '../Components/AdminUsersComponent/AdminUsersComponent';
import AdminWebsiteVisitorsComponent from '../Components/AdminWebsiteVisitorsComponent/AdminWebsiteVisitorsComponent';
function NewsItemPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <NewsItem {...props} urlParams={urlParams} />
            </React.Fragment> 
}

function HomePage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Home {...props} urlParams={urlParams} />
            </React.Fragment>  
}
function BookmarksPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Bookmarks {...props} urlParams={urlParams} />
            </React.Fragment>  
}
function TrendingToday (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <TrendingTodayPage {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function TrendingPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Trending {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function WordsEditorPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <WordsEditor {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function WordsEditorQaPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <WordsEditorQa {...props} urlParams={urlParams} />
            </React.Fragment>  
}
function SubscribersPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <Subscribers {...props} urlParams={urlParams} />
            </React.Fragment>  
}
function CreateAccountPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <CreateAccount {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function YourAccountPage (props) {
    let urlParams = useParams();
    return <React.Fragment>
                <YourAccount {...props} urlParams={urlParams} />
            </React.Fragment>  
}

function ZopinionsPage(props)
{
    let urlParams = useParams();
    return <Zopinions {...props} urlParams={urlParams} />
}
function RecoverPassowrdPage(props)
{
    let urlParams = useParams();
    return <RecoverPasswordComponent {...props} urlParams={urlParams} />
}

function TrendingWhitelistWordsPage(props)
{
    let urlParams = useParams();
    return <TrendingWhitelistWords {...props} urlParams={urlParams} />
}
function TrendingBlacklistWordsPage(props)
{
    let urlParams = useParams();
    return <TrendingBlacklistWords {...props} urlParams={urlParams} />
}
function AdminUsersPage(props)
{
    let urlParams = useParams();
    return <AdminUsersComponent {...props} urlParams={urlParams} />
}
function AdminWebsiteVisitorsComponentPage(props) {
    let urlParams = useParams();
  return <React.Fragment>
              <AdminWebsiteVisitorsComponent {...props} urlParams={urlParams} />
          </React.Fragment>
}
export default function RouterSwitch(props) {
    function changeRoute(route) {
        window.location.href = route;
    }

    return (<Routes>
                <Route path="/recover/password/:uET/:uCF" element={ <RecoverPassowrdPage {...props} changeRoute={changeRoute} />} />
                <Route path="/" element={<Home {...props} changeRoute={changeRoute} />} />
                <Route path="/search/:query" element={<HomePage {...props} changeRoute={changeRoute} />} />
                <Route path="/filters" element={<FiltersList {...props} changeRoute={changeRoute} />} />
                <Route path="/link/:linksHash" element={<NewsItemPage {...props} changeRoute={changeRoute} />} />
                <Route path="/bookmarks" element={<BookmarksPage {...props} changeRoute={changeRoute} />} />
                <Route path="/trending" element={<TrendingPage {...props} changeRoute={changeRoute} />} />
                <Route path="/trending/today" element={<TrendingToday {...props} changeRoute={changeRoute} />} />
                <Route path="/trending/whitelist" element={<TrendingWhitelisted {...props} changeRoute={changeRoute} />} />
                <Route path="/trending/whitelist/words" element={<TrendingWhitelistWordsPage {...props} changeRoute={changeRoute} />} />
                <Route path="/trending/blacklist/words" element={<TrendingBlacklistWordsPage {...props} changeRoute={changeRoute} />} />
                <Route path="/subscribers/:subscribersID" element={<SubscribersPage {...props} changeRoute={changeRoute} />} />
                <Route path="/create-account" element={<CreateAccountPage {...props} changeRoute={changeRoute} />} />
                <Route path="/your-account" element={<YourAccountPage {...props} changeRoute={changeRoute} />} />
                <Route path="/zopinions" element={<ZopinionsPage {...props} changeRoute={changeRoute} />} />
                <Route path="/words" element={<WordsEditorPage {...props} changeRoute={changeRoute} />} />
                <Route path="/words/qa" element={<WordsEditorQaPage {...props} changeRoute={changeRoute} />} />
                <Route path="/gdpr-policy" element={
                    <React.Fragment>
                        <StaticPagesComponent page="gdpr-policy"  {...props} changeRoute={changeRoute} />
                    </React.Fragment>
                } />
                <Route path="/admin/users/page/:page/search/:query" search={true} element={<AdminUsersPage {...props} changeRoute={changeRoute} />} />
                <Route path="/admin/users/page/:page" element={<AdminUsersPage {...props} changeRoute={changeRoute} />} />
                <Route path="/admin/users/search/:query" search={true} element={<AdminUsersPage {...props} changeRoute={changeRoute} />} />
                <Route path="/admin/users" element={<AdminUsersPage {...props} changeRoute={changeRoute} />} />
                <Route path="/visitors/page/:page/:query" element={<AdminWebsiteVisitorsComponentPage search={true} {...props} changeRoute={changeRoute} />} />
                <Route path="/visitors/page/:page" element={<AdminWebsiteVisitorsComponentPage search={true} {...props} changeRoute={changeRoute} />} />
                <Route path="/visitors/:query" element={<AdminWebsiteVisitorsComponentPage search={true} {...props} changeRoute={changeRoute} />} />
                <Route path="/visitors" element={<AdminWebsiteVisitorsComponentPage search={true} {...props} changeRoute={changeRoute} />} />
            </Routes>)
}