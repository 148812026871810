import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Style from './Styles/OrderItemsElementStyle'

export default function StocksItemComponent(props) {
    const classes = Style();

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
      const componentProps = {
          ...props.componentProps
      }

return (<TableRow>
            <TableCell>{props.element.websiteVisitorsID}</TableCell>
            <TableCell><a href={props.element.websiteVisitorsRequestUri} target="_blank">{props.element.websiteVisitorsRequestUri}</a></TableCell>
            <TableCell>{props.element.websiteVisitorsReferrer}</TableCell>
            <TableCell><a href={"/visitors/" + props.element.websiteVisitorsIpSha}>{props.element.websiteVisitorsIpSha}</a></TableCell>
            <TableCell>{props.element.websiteVisitorsDateTime}</TableCell>
            <TableCell>{props.element.websiteVisitorsSection}</TableCell>
            <TableCell>{props.element.websiteVisitorsUserAgent}</TableCell>
        </TableRow>
  );
}