import React from 'react';
import Button from '@mui/material/Button';
import SnackbarContent from '@mui/material/SnackbarContent';
import CoreComponent from "../../../../Core/CoreComponent";
export default class CookiesSnackbarComponent extends CoreComponent {

  constructor(props) {
      super(props);
      this.loadComponentsProps()
      this.basicComponentConstants = {
          language: props.componentMethods.getManagerState().language,
          history: props.history
      }
  }

  componentDidMount() {
      super.componentDidMount()
  }
   getRender() {

        let classes = {
              root: {
                position: "fixed",
                left: "8px",
                bottom: "70px",
                maxWidth: 600,
                '& > * + *': {
                  marginTop: "16px",
                },
              },
              link: {
                color:"white"
              }
            }
        this.loadComponentsProps()
        if(this.getLocalStorage('cookiesPolicy')) {
          return null
        } else {
          return <div style={classes.root}>
                  <SnackbarContent
                    message={<React.Fragment>{this.props.componentMethods.getManagerState().language.LANG_GDPR_SNACKBAR_TITLE}&nbsp;
                    {this.props.componentMethods.getManagerState().language.LANG_GDPR_SNACKBAR_CONTENT}
                </React.Fragment>}
                    action={
                      <Button onClick={() => {this.saveLocalStorage(true,"cookiesPolicy"); this.props.componentMethods.setManagerState({cookiePolicy: true})}} color="secondary" variant="contained">
                    {this.props.componentMethods.getManagerState().language.LANG_CONSENT}<br />
                      </Button>}
                  />
                </div>          
        }
    }
}