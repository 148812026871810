import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/HomeStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Login from '../Login/Login'
import NewsFeed from '../NewsFeed/NewsFeed'
import SearchFeed from '../SearchFeed/SearchFeed';
import Typography from '@mui/material/Typography';
import TrendingTodayLinks from '../TrendingTodayLinks/TrendingTodayLinks';
export default class UserOrderComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.getSubscribersFeedAction()
            },
            getLinksAction: (q) => {
                that.getLinksAction(q)
            },
            postSubscriberKeyword: (subscribersKeyword) => {
                that.postSubscriberKeyword(subscribersKeyword)
            },
            getTrendingLinkxFeed: () => {
                that.getTrendingLinkxFeed()
            }
        })
    }
    
    getTrendingLinkxFeed() {
        const that = this;
        that.props.setManagerState({loadingTrendingFeed: true, trendingFeedItems: []},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_TRENDING_LINKS + '?limit=8',{},'GET',null, (data) => {
                that.props.setManagerState({trendingFeedItems: data.items, loadingTrendingFeed: false})
            }, () => {
                that.props.setManagerState({loadingTrendingFeed: false})
            })
        })
    }


    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar postWebsiteVisitors={true} {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        <Login {...this.buildComponentProps('Appbar')} />
                        <TrendingTodayLinks  withoutMargin={true} {...this.buildComponentProps('Appbar')} />
                    </div>
                </div>
    }
}
