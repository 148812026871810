import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import LoadingComponent from '../../CircularProgress/CircularProgress'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Style from './Styles/OrderItemsElementStyle'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';


export default function UserItemComponent(props) {
    const classes = Style();
    const [isLoadedIsAffiliate, setIsLoadedAffiliate] = React.useState(false)
    const [isAffiliate, setIsAffiliate] = React.useState(false)
    const [isLoadedPhotographer, setIsLoadedPhotographer] = React.useState(false)
    const [isPhotographer, setIsPhotographer] = React.useState(false)
    const [affiliatesPercentage, setAffiliatesPercentage] = React.useState(0)
    const [photographerReward, setPhotographerReward] = React.useState(0)
    const [userAccessLevels, setUserAccessLevels] = React.useState([])
    const [loadedUserAccessLevels, setLoadedUserAccessLevels] = React.useState(false)
    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
      const componentProps = {
          ...props.componentProps
      }

      const getUserAccessLevelsAction = () => {

        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADMIN_USERS_ACCESS_LEVELS + props.element.usersID,{},'GET',null, (data) => {
            setUserAccessLevels(data.items)
            setLoadedUserAccessLevels(true)
        })
      }
      const hasAccessLevel = (accessLevelsID) => {
        for (let i in userAccessLevels)
        {
            if (parseInt(accessLevelsID) === parseInt(userAccessLevels[i].usersAccessLevelsAccessLevelsID))
                return true
        }

        return false
      }

      const getUsersAccessLevel = (accessLevelsID) => {
        for (let i in userAccessLevels)
        {
            if (parseInt(accessLevelsID) === parseInt(userAccessLevels[i].usersAccessLevelsAccessLevelsID))
                return userAccessLevels[i]
        }

        return false
      }

      const postUsersAccessLevels = (accessLevelsID) => {

        let postObject = {
            usersAccessLevelsUsersID: props.element.usersID,
            usersAccessLevelsAccessLevelsID: accessLevelsID
        }
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADMIN_ACCESS_LEVELS,postObject,'POST',null, (data) => {
            getUserAccessLevelsAction()
        })
      }

      const deleteUsersAccessLevelsAction = (usersAccessLevelsID) => {
            componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADMIN_ACCESS_LEVELS + '/' + usersAccessLevelsID,{},'DELETE',null, (data) => {
                getUserAccessLevelsAction()
            })
      }

      useEffect(() => {
            getUserAccessLevelsAction()
        }, []);
return (<TableRow>
            <TableCell>
                {props.element.usersID}
            </TableCell>
            <TableCell>
                {props.element.usersEmail}
            </TableCell>
            <TableCell>
                {props.element.usersFirstName}
            </TableCell>
            <TableCell>
                {props.element.usersLastName}
            </TableCell>
            <TableCell>
                {props.element.usersDateOfBirth}
            </TableCell>
            <TableCell>
                {props.element.usersLastLogin}
            </TableCell>
            <TableCell>
                {componentConstants.language[Settings.SETTINGS_USERS_SEX_ID[props.element.usersUsersSexID]]}
            </TableCell>
            <TableCell>
                {
                    !componentMethods.getManagerState().loadingAdminAccessLevels && loadedUserAccessLevels ?
                    <React.Fragment>
                        {componentMethods.getManagerState().adminAccessLevels.map((el, idx) => {
                            return <div key={idx} >
                                        {hasAccessLevel(el.accessLevelsID) ?
                                            <IconButton onClick={() => {deleteUsersAccessLevelsAction(getUsersAccessLevel(el.accessLevelsID).usersAccessLevelsID)}}><CheckBoxIcon /></IconButton>
                                            :
                                            <IconButton onClick={() => {postUsersAccessLevels(el.accessLevelsID)}}><ClearIcon /></IconButton>
                                        }
                                        {el.accessLevelsName}
                                    </div>
                        })}
                    </React.Fragment>
                    :
                    <LoadingComponent />
                }
            </TableCell>
        </TableRow>
  );
}